import { Chevron, MenuItem, Typography } from '@hermes/web-components'
import React from 'react'
import { Selector, SelectorLabel, SelectorLabelIcon } from './FooterSelector.styles'
import { MenuPaperStyles } from './SearchSelector.styles'

export interface ItemInterface {
  value: string
  label: string
  show: boolean
  icon?: () => React.ReactNode
}

interface SelectorProps {
  value: string
  setValue: (value: string) => void
  itemsArray: ItemInterface[]
  labelIcon?: () => React.ReactNode
}

export const FooterSelector = ({ value, setValue, itemsArray, labelIcon }: SelectorProps) => {
  const Label = (labelValue: string) => {
    const item = itemsArray.find((lang) => lang.value === labelValue)
    return (
      <SelectorLabel>
        {labelIcon ? labelIcon() : <>{item?.icon && <SelectorLabelIcon>{item?.icon()}</SelectorLabelIcon>}</>}
        <Typography fontSize="16px" lineHeight="20px" fontWeight="400">
          {item?.label}
        </Typography>
      </SelectorLabel>
    )
  }
  return (
    <Selector
      value={value}
      MenuProps={{
        PaperProps: { sx: MenuPaperStyles }
      }}
      IconComponent={Chevron}
      renderValue={(renderValue) => Label(String(renderValue))}
      onChange={(e) => setValue(String(e.target.value))}
    >
      {itemsArray.map((item, index) => (
        <MenuItem key={index} value={item.value} disabled={!item.show || value === item.value}>
          {item?.icon && <SelectorLabelIcon>{item?.icon()}</SelectorLabelIcon>}
          <Typography fontWeight={500} fontSize="14px" lineHeight="20px">
            {item.label}
          </Typography>
        </MenuItem>
      ))}
    </Selector>
  )
}
