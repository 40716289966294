import { styledComponent, Box, Select, Typography } from '@hermes/web-components'

export const SubFooterContainer = styledComponent(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.neutral[200]}`,
  marginTop: '26px',
  padding: '20px 0',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  flexDirection: 'row',
  [theme.breakpoints.down('sm')]: {
    padding: '0',
    flexDirection: 'column-reverse'
  }
}))

export const SubFooterItem = styledComponent(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignContent: 'center',
  alignItems: 'center',
  width: '20%',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    width: '100%',
    padding: '17px 0',
    ':not(:first-of-type)': {
      borderBottom: `1px solid ${theme.palette.neutral[200]}`
    }
  },
  [theme.breakpoints.up('sm')]: {
    ':not(:last-child)': {
      paddingRight: '32px'
    },
    ':last-child': {
      width: 'auto'
    },
    gap: '25px'
  }
}))

export const Selector = styledComponent(Select)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  alignContent: 'center',
  svg: {
    top: 0,
    width: '25px'
  },
  '.MuiSelect-select': {
    padding: 0,
    display: 'flex',
    alignContent: 'center'
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderWidth: 0,
    borderRadius: 0
  },
  '&.Mui-focused': {
    '.MuiOutlinedInput-notchedOutline': {
      borderWidth: 0
    }
  },
  input: {
    position: 'absolute',
    height: '100%',
    padding: '0 !important',
    margin: '0 !important'
  },
  '.MuiMenu-paper': {
    borderRadius: '100px'
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}))

export const SelectorLabel = styledComponent(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  gap: '8px',
  alignContent: 'center'
})

export const SelectorLabelIcon = styledComponent(Box)({
  overflow: 'hidden',
  borderRadius: '100%',
  width: '20px',
  height: '20px',
  display: 'grid',
  justifyContent: 'center',
  alignContent: 'center',
  svg: {
    height: '23px'
  }
})

export const DetectedLocationMessage = styledComponent(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main,
  fontSize: '16px',
  lineHeight: '20px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px'
  }
}))

export const MenuPaperStyles = {
  borderRadius: '20px',
  maxHeight: '200px',
  boxShadow: '0 8px 24px rgb(0 0 0 / 8%)',
  ul: {
    display: 'grid',
    minWidth: '193px',
    padding: 0,
    li: {
      padding: '16px',
      display: 'inline-flex',
      gap: '8px',
      '.MuiBox-root': {
        overflow: 'hidden',
        borderRadius: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        svg: {
          height: '100%',
          position: 'absolute'
        }
      }
    }
  }
}
