'use client'

import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { Alert, Box, Typography } from '@hermes/web-components'

export type FlashMessageType = 'success' | 'error' | 'warning'

export interface FlashMessage {
  message: string
  type: FlashMessageType
}

interface FlashMessageContextProps {
  addFlashMessage: (message: string, type: FlashMessageType) => void
}

const FlashMessageContext = createContext<FlashMessageContextProps>({
  addFlashMessage: () => {}
})

const FlashMessageProvider = ({ children }: { children: React.ReactNode }) => {
  const [flashMessages, setFlashMessages] = useState<FlashMessage[]>([])
  const timerRef = useRef<any>()
  useEffect(() => {
    if (flashMessages.length > 0) {
      timerRef.current = setTimeout(() => {
        setFlashMessages((prevMessages) => prevMessages.slice(1))
      }, 2000)
    }

    return () => clearTimeout(timerRef.current)
  }, [flashMessages])

  const addFlashMessage = (message: string, type: FlashMessageType) => {
    setFlashMessages((prevMessages) => [...prevMessages, { message, type }].slice(-5))
  }

  const removeFlashMessage = (index: number) => {
    setFlashMessages((prevMessages) => prevMessages.filter((_, i) => i !== index))
  }

  const renderFlashMessages = () => (
    <Box position="fixed" width="100%" bottom="40px" zIndex={999}>
      <Box margin="0 auto" display="grid" gap="10px" width="100%" maxWidth="449px">
        {flashMessages.map((flashMessage, index) => (
          <Alert key={index} type={flashMessage.type} onClose={() => removeFlashMessage(index)}>
            <Typography>{flashMessage.message}</Typography>
          </Alert>
        ))}
      </Box>
    </Box>
  )

  return (
    <FlashMessageContext.Provider value={{ addFlashMessage }}>
      {children}
      {renderFlashMessages()}
    </FlashMessageContext.Provider>
  )
}

export const useFlashMessage = () => useContext(FlashMessageContext)

export default FlashMessageProvider
