import { EnFlag, MapIcon, NoFlag, SeFlag, Typography } from '@hermes/web-components'
import { useLocale } from '@hooks/useLocale'
import useTranslateMessage from '@hooks/useTranslateMessage'
import getRegionFromLocale from '@lib/utils/getRegionFromLocale'
import { FooterSelector } from './FooterSelector'
import { DetectedLocationMessage, SubFooterContainer, SubFooterItem } from './SubFooter.styles'

const SubFooter = () => {
  const { t } = useTranslateMessage()
  const locale = useLocale()
  const region = getRegionFromLocale(locale)

  const languages = [
    { value: `en-${region}`, label: t({ id: 'lang.english' }), icon: () => EnFlag(), show: true },
    { value: 'no', label: t({ id: 'lang.norway' }), icon: () => NoFlag(), show: region === 'no' },
    { value: 'se', label: t({ id: 'lang.sweden' }), icon: () => SeFlag(), show: region === 'se' }
  ]

  const regions = [
    { value: 'no', label: t({ id: 'region.norway' }), show: true },
    { value: 'se', label: t({ id: 'region.sweden' }), show: true }
  ]

  return (
    <SubFooterContainer>
      <SubFooterItem>
        <Typography fontSize="16px" lineHeight="24px">
          © {new Date().getFullYear()} Bookis AS
        </Typography>
      </SubFooterItem>
      <SubFooterItem>
        <FooterSelector
          value={locale}
          setValue={(e) => {
            const pathArray = window.location.pathname.split('/')
            pathArray.shift()
            pathArray.shift()
            window.location.href = `${window.location.origin}/${e}/${pathArray.join('/')}`
          }}
          itemsArray={languages}
        />
      </SubFooterItem>
      <SubFooterItem>
        <FooterSelector
          value={region}
          setValue={(e) => {
            window.location.href = `${window.location.origin}/${e}`
          }}
          itemsArray={regions}
          labelIcon={() => MapIcon({ width: '20px', height: '20px' })}
        />
        <DetectedLocationMessage>{t({ id: 'footer.regionDetectedByIP' })}</DetectedLocationMessage>
      </SubFooterItem>
    </SubFooterContainer>
  )
}

export default SubFooter
