'use client'

import { Container, CssBaseline, theme } from '@hermes/web-components'
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar'
import React from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import 'swiper/css'
import 'swiper/css/pagination'

import { Footer } from '@components/Footer'
import { Header } from '@components/Header'
import FlashMessageProvider from '@lib/contexts/flashMessageContext'

export const RootLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <>
    <CssBaseline />
    <Header />
    <FlashMessageProvider>
      {children}
      <ProgressBar
        color={theme.palette.primary.main}
        startPosition={0.3}
        stopDelay={200}
        height={'3px'}
        options={{ showSpinner: false }}
      />
    </FlashMessageProvider>
    <Container maxWidth="xl" sx={{ backgroundColor: '#fff' }}>
      <Footer />
    </Container>
  </>
)
